import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import WebServiceClient from 'clients/WebServiceClient';
import LinkComp from 'components/atoms/LinkComp';
import { ICON_HISTORY_CLOCK } from 'constants/Icons';
import { INGREDIENT, PRODUCTS_LOADING_URL, SELLERS, getPathProductBySlug } from 'constants/Paths';
import { HOAT_CHAT, NHA_BAN_HANG, THUOC, THUOC_VA_HOAT_CHAT } from 'constants/data';
import { useSearch } from 'context/Search';
import { useSetting } from 'context/Settings';
import { memo, useEffect, useState } from 'react';
import ProductServiceV2 from 'services/ProductServiceV2';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { getLinkProxyFile } from 'utils/ImageUtils';
import { groupArrayOfObjects } from 'utils/ObjectUtils';
import gtag from 'utils/gtag';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

const MostSearching = ({ hashtagTopSearch, handleClickOutside }) => {
  useEffect(() => {
    setTimeout(() => {
      gtag.displayTopSearch();
      gtag.showTopSearch(hashtagTopSearch);
    }, 300);
  }, []);
  if (!hashtagTopSearch || hashtagTopSearch?.length === 0) return <></>;
  return (
    <div className={styles.mostSearching}>
      <Box display="inline" className={styles.label}>
        <Typography display="inline" style={{ fontWeight: '500', fontSize: 14 }}>
          Tìm kiếm nhiều nhất
        </Typography>
      </Box>
      <Box className={styles.results}>
        {hashtagTopSearch.map((_tag) => {
          return (
            <LinkComp
              onClick={() => {
                handleClickOutside(false);
                gtag.clickTopSearch(_tag);
              }}
              href={_tag.url}
              className={styles.tag}
              key={_tag.hashtag}
              data-test="search-dropdown-enter"
            >
              <div className={styles.dot} />
              <span className={styles.hashtag}>{_tag.hashtag}</span>
              {!!_tag?.svgIcon && (
                <ImageFallbackStatic width={16} height={16} objectFit="contain" src={getLinkProxyFile(_tag.svgIcon || '')} alt="img" />
              )}
            </LinkComp>
          );
        })}
      </Box>
    </div>
  );
};

const RecentSearchTexts = memo(({ texts = [], searchType = 1 }) => {
  const { handleClickRecentKeyword } = useSearch();
  return (
    <Box>
      <div className={styles.recentSearchTextContainer}>
        <ICON_HISTORY_CLOCK /> <span className={styles.recentSearchTextContent}>Lịch sử tìm kiếm</span>
      </div>
      {texts.map((text) => (
        <div className={styles.hoveredRecentSearchText} onClick={() => handleClickRecentKeyword(text, searchType)} key={text} role="button">
          {text}
        </div>
      ))}
    </Box>
  );
});

// TODO: logic search
const SearchDropdown = (props) => {
  const { data, keyword, type, handleClickOutside } = props;
  const { getNameSeller } = useSetting();
  const hashtagTopSearch = useStore((state) => state.hashtagTopSearch);
  const [recentSearchTexts, setRecentSearchTexts] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getRecentSearchTexts = async () => {
      const resp = await WebServiceClient.getRecentSearchTexts();
      if (resp.status === 'OK' && isMounted) {
        setRecentSearchTexts(resp.data || []);
      }
    };
    if (keyword === '') {
      getRecentSearchTexts();
    }
    return () => {
      isMounted = false;
    };
  }, [keyword]);

  if (!data || !type) {
    return (
      <Box className={styles.searchDropdown} data-test="search-dropdown">
        <Typography display="inline" className={styles.searchResults}>
          Không có sản phẩm với từ khóa <Typography display="inline">{keyword}</Typography>&nbsp;&nbsp;trong&nbsp;
          <Typography className={styles.textPrimary}>Tất Cả Sản Phẩm</Typography>
        </Typography>
      </Box>
    );
  }

  // kq của thuốc
  if (data && (type === THUOC_VA_HOAT_CHAT || type === THUOC)) {
    const listSearch = data.map((item) => {
      const info = ProductServiceV2.convertProductV2(item);
      const { displayPriceFormated, displayPrice, tags, name, seller } = info;
      const sellerInfo = getNameSeller({ seller, tags });

      return {
        ...item,
        name,
        displayPriceFormated,
        displayPrice,
        sellerName: sellerInfo?.individualNonVatSellerName || sellerInfo?.sellerName || '',
      };
    });

    const groupedProductByName = groupArrayOfObjects(listSearch, 'name');

    return (
      <Box className={styles.searchDropdown} data-test="search-dropdown">
        {keyword !== '' && (
          <LinkComp className={styles.searchResults} href={`${PRODUCTS_LOADING_URL}?text=${keyword}`} data-test="search-dropdown-enter">
            <Box display="inline" className={styles.keyword}>
              <Typography display="inline">{keyword}</Typography>
            </Box>
            &nbsp;Trong:&nbsp;
            <Typography display="inline" className={styles.textPrimary}>
              Thuốc
            </Typography>
          </LinkComp>
        )}
        <Box className={styles.styledScroll}>
          {Object.keys(groupedProductByName).map((key) => {
            const listProduct = groupedProductByName[key];

            return listProduct
              ?.sort((a, b) => a?.displayPrice - b?.displayPrice)
              ?.map((item) => {
                // hard code flagship store
                return (
                  <LinkComp
                    className={styles.searchResults}
                    key={`${key}-${item.sku.slug}`}
                    href={getPathProductBySlug(item.sku.slug)}
                    data-test="search-dropdown-item"
                  >
                    {item?.product?.name}{' '}
                    {listProduct.length > 1 && `${item?.sellerName && `- ${item?.sellerName}`}  - ${item?.displayPriceFormated}`}
                  </LinkComp>
                );
              });
          })}
        </Box>
        {data?.length <= 0 && keyword === '' && <RecentSearchTexts texts={recentSearchTexts} />}
        <MostSearching hashtagTopSearch={hashtagTopSearch} handleClickOutside={handleClickOutside} />
      </Box>
    );
  }

  // kq của hoạt chất
  if (data && type === HOAT_CHAT) {
    const searchRes = data.map((item) => ({ name: item.name, link: `${INGREDIENT}/${item?.slug}` }));

    return (
      <Box className={styles.searchDropdown} data-test="search-dropdown">
        {keyword !== '' && (
          <LinkComp className={styles.searchResults} href={`${INGREDIENT}`}>
            <Box display="inline" className={styles.keyword}>
              <Typography display="inline">{keyword}</Typography>
            </Box>
            &nbsp;Trong:&nbsp;
            <Typography display="inline" className={styles.textPrimary}>
              Hoạt Chất
            </Typography>
          </LinkComp>
        )}
        <Box className={styles.styledScroll}>
          {searchRes?.map((item) => (
            <LinkComp className={styles.searchResults} key={item.name} href={item.link}>
              {item?.name}
            </LinkComp>
          ))}
        </Box>
        {data?.length <= 0 && keyword === '' && <RecentSearchTexts texts={recentSearchTexts} />}
        <MostSearching hashtagTopSearch={hashtagTopSearch} handleClickOutside={handleClickOutside} />
      </Box>
    );
  }

  // kq của nhà bán hàng
  if (data && type === NHA_BAN_HANG) {
    return (
      <Box className={styles.searchDropdown} data-test="search-dropdown">
        {keyword !== '' && (
          <LinkComp className={styles.searchResults} href={`${SELLERS}?search=${keyword}`}>
            <Box display="inline" className={styles.keyword}>
              <Typography display="inline">{keyword}</Typography>
            </Box>
            &nbsp;Trong:&nbsp;
            <Typography display="inline" className={styles.textPrimary}>
              Nhà Bán Hàng
            </Typography>
          </LinkComp>
        )}
        <Box className={styles.styledScroll}>
          {data?.map((item) => (
            <LinkComp className={styles.searchResults} key={item.name} href={item.link}>
              {item.name}
            </LinkComp>
          ))}
        </Box>
        {data?.length <= 0 && keyword === '' && <RecentSearchTexts texts={recentSearchTexts} />}
        <MostSearching hashtagTopSearch={hashtagTopSearch} handleClickOutside={handleClickOutside} />
      </Box>
    );
  }

  return (
    <Box className={styles.searchDropdown} data-test="search-dropdown">
      <Typography display="inline" className={styles.searchResults}>
        Không có sản phẩm với từ khóa <Typography display="inline">{keyword}</Typography>&nbsp;&nbsp;trong&nbsp;
        <Typography className={styles.textPrimary}>Tất Cả Sản Phẩm</Typography>
      </Typography>
    </Box>
  );
};

export default memo(SearchDropdown);

