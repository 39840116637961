import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getData } from 'clients/Clients';
import SearchClient from 'clients/SearchClient';
import { searchStoreByText } from 'clients/SellerClient';
import clsx from 'clsx';
import OverlayV2 from 'components-v2/atoms/OverlayV2';
import { SEARCHV2ACTIVE_ICON, SEARCHV2_ICON } from 'constants/Images';
import { PRODUCTS_URL, SELLERS } from 'constants/Paths';
import { ENTERPRISE, HOAT_CHAT, NHA_BAN_HANG, PAGE_SIZE_30, SEARCH_TYPE_LIST, THUOC, THUOC_VA_HOAT_CHAT } from 'constants/data';
import { useSetting } from 'context/Settings';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { memo, useEffect, useRef, useState } from 'react';
import WebService from 'services/WebService';
import { IS_WEB_SERVICE_SEARCH_LITE } from 'sysconfig';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { searchStringInStrings } from 'utils/StringUtils';
import { debounceFunc200, debounceFunc400 } from 'utils/debounce';
import deviceUtils from 'utils/deviceUtils';
import gtag from 'utils/gtag';
import { useStore } from 'zustand-lib/storeGlobal';
import useSearchResult from 'zustand-lib/useSearchResult';
import SearchDropdown from '../SearchDropdown';
import styles from './styles.module.css';

// SEARCH PRODUCT

const ExpandIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9182 16.006C12.4233 16.5418 11.5768 16.5418 11.0818 16.006L7.01006 11.5982C6.2705 10.7976 6.83834 9.5 7.92825 9.5H16.0718C17.1617 9.5 17.7296 10.7976 16.99 11.5982L12.9182 16.006Z"
      fill="#555555"
    />
  </svg>
);

const SearchInput = memo(({ classCustom, scrollSticky = null, ...restProps }) => {
  const wrapperRef = useRef(null);
  const { ingredients, getNameSeller } = useSetting();
  const [isFocus, setIsFocus] = useState(false);
  const [searchType, setSearchType] = useState(THUOC_VA_HOAT_CHAT);

  const [keyword, setKeyword] = useState('');
  const [searchProduct, setSearchProduct] = useState([]);
  const [hidden, setHidden] = useState(false);
  const [searchIngredient, setSearchIngredient] = useState([]);
  const [searchSeller, setSearchSeller] = useState([]);

  const inputEl = useRef(null);
  const [show, setShow] = useState(false);
  const [seacrIcon, setSearchIcon] = useState(false);
  const handleSendEventSearchTimeout = useSearchResult((state) => state.handleSendEventSearchTimeout);

  const suggestIndex = useRef(0);
  const suggestKeywordsRef = useRef(null);
  const [isVisibleSuggest, setIsVisibleSuggest] = useState(true);
  const hashtagTopSearch = useStore((state) => state.hashtagTopSearch);
  const [currentIndex, setCurrentIndex] = useState(0);
  const router = useRouter();
  const clearSearchRes = () => {
    setSearchProduct([]);
    setSearchIngredient([]);
    setSearchSeller([]);
  };
  const handleFocus = (e) => {
    const val = e?.target?.value;
    if (val && val.length > 0) {
      setHidden(true);
      setKeyword(e.target.value);
    }
  };
  const handleChangeTypeSearch = (e) => {
    setSearchType(Number(e.target.value));
  };
  const handleKeyDown = (event) => {
    const trimKeyword = keyword.trim();

    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
      switch (searchType) {
        case THUOC_VA_HOAT_CHAT:
        case THUOC:
          router.push({
            pathname: `${PRODUCTS_URL}`,
            query: {
              q: trimKeyword,
              searchType,
            },
          });
          break;
        // TODO: improve phase 2
        // enter ko chuyển đến page hoạt chất
        case HOAT_CHAT:
          // router.push(`${INGREDIENT}`);
          break;
        case NHA_BAN_HANG:
          router.push({
            pathname: `${SELLERS}`,
            query: {
              search: trimKeyword,
              searchType,
            },
          });
          break;
        default:
          router.push(`${PRODUCTS_URL}`);
          break;
      }
    }
  };
  const convertSellers = (seller = []) =>
    seller
      ?.sort((a, b) => a?.name.localeCompare(b?.name))
      ?.map((itemSeller) => {
        const { name = null, slug = null, code = null } = itemSeller || {};
        const info = getNameSeller({ seller: { code } });
        const { linkStore = '', linkSeller = '' } = info || {};
        return {
          name,
          slug,
          code,
          link: linkStore || linkSeller,
        };
      });
  useEffect(() => {
    if (!suggestKeywordsRef.current || !hashtagTopSearch?.length > 1) return;

    const updateSuggestKeywordDisplay = (index) => {
      if (suggestKeywordsRef.current && !isFocus && isVisibleSuggest) {
        const suggestKeywordListElm = suggestKeywordsRef.current.getElementsByClassName('suggest_keyword');
        Array.from(suggestKeywordListElm).forEach((item, i) => {
          const currentItem = item;
          currentItem.style.opacity = i === index ? '1' : '0';
          currentItem.style.transform = i === index ? 'translateY(55%)' : 'translateY(100%)';
        });
      }
    };

    updateSuggestKeywordDisplay(suggestIndex.current);

    const interval = setInterval(() => {
      const nextIndex = (suggestIndex.current + 1) % hashtagTopSearch.length;
      updateSuggestKeywordDisplay(nextIndex);
      setCurrentIndex(nextIndex);
      suggestIndex.current = nextIndex;
    }, 2500);

    return () => clearInterval(interval);
  }, [hashtagTopSearch.length, isVisibleSuggest, keyword, isFocus]);

  const focusRef = useRef(false);
  const handleSendEvSearchTimeout = useSearchResult((state) => state.handleSendEvSearchTimeout);

  useEffect(() => {
    if (document.activeElement === inputEl.current) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [hidden]);

  const containerRef = useRef(null);

  const handleClickSearch = () => {
    focusRef.current = true;
    setIsFocus(true);
  };

  const handleShowFirstClick = () => {
    setShow(true);
    setSearchIcon(true);
    inputEl.current?.focus();

    if (keyword.length <= 0) {
      clearSearchRes();
    }
    handleClickSearch();
  };

  const checkStickySelect = () => {
    if (!scrollSticky) {
      if (isFocus) {
        return styles.focusSelect;
      }
      return null;
    }
    if (isFocus && scrollSticky) {
      return styles.stickySelect;
    }
    return styles.hiddenSelect;
  };

  const checkStickyInput = () => {
    if (!scrollSticky || isFocus || deviceUtils.isTablet()) {
      return null;
    }
    return styles.root_input_sticky;
  };

  const getDataSearch = () => {
    switch (searchType) {
      case THUOC_VA_HOAT_CHAT:
      case THUOC:
        if (searchProduct?.text === keyword) {
          return searchProduct?.data;
        }
        return [];
      case HOAT_CHAT:
        return searchIngredient;
      case NHA_BAN_HANG:
        if (searchSeller?.text === keyword) {
          return searchSeller?.data;
        }
        return [];
      default:
        return [];
    }
  };

  const handleSearch = async ({ val = '', isIngredients = true }) => {
    const resData = IS_WEB_SERVICE_SEARCH_LITE
      ? await WebService.searchFuzzyLite(
          {
            body: {
              text: val,
              offset: 0,
              limit: PAGE_SIZE_30,
              searchStrategy: {
                text: true,
                keyword: true,
                ingredient: isIngredients,
              },
              queryOption: {
                isReplacePriceAfterVoucher: true,
              },
            },
          },
          true,
        )
      : await SearchClient.searchByKeywords(val, isIngredients);
    setSearchProduct({ data: getData(resData), text: val });

    handleSendEventSearchTimeout({ resData, searchKeyword: val });
  };
  const handleBlur = () => {
    setIsFocus(false);
    debounceFunc200(() => {
      setHidden(false);
    });
  };
  const fetchDataSearch = async (val) => {
    switch (searchType) {
      case THUOC_VA_HOAT_CHAT:
        handleSearch({ val });
        gtag.clickSearch(THUOC_VA_HOAT_CHAT, val);
        break;
      case HOAT_CHAT:
        {
          const data = searchStringInStrings(ingredients, val);
          handleSendEvSearchTimeout(val, data?.length > 0 ? 'OK' : 'NOT_FOUND', data?.length);
          setSearchIngredient(data);
          gtag.clickSearch(HOAT_CHAT, val);
        }
        break;
      case NHA_BAN_HANG:
        {
          const sellerRes = await searchStoreByText({
            params: {
              search: val,
              offset: 0,
              limit: 30,
              getTotal: true,
              sellerType: ENTERPRISE,
            },
          });
          handleSendEvSearchTimeout(val, sellerRes?.status, sellerRes?.total);
          setSearchSeller({ data: convertSellers(getData(sellerRes)), text: val });
          gtag.clickSearch(NHA_BAN_HANG, val);
        }
        break;
      case THUOC:
        handleSearch({ val, isIngredients: false });
        gtag.clickSearch(THUOC, val);
        break;
      default:
        clearSearchRes();
        break;
    }
  };

  const handleSearchbox = (e) => {
    const val = e.target.value;
    setKeyword(val);
    setHidden(true);
    debounceFunc400(() => {
      if (val) {
        fetchDataSearch(val);
      } else {
        clearSearchRes();
      }
    });
  };
  const handleOnBlur = () => {
    debounceFunc200(() => {
      if (focusRef.current) {
        focusRef.current = false;
      } else {
        handleBlur();
      }
    });
  };

  const [focusSelect, setFocusSelect] = useState(false);

  const handleChangeSelect = (e) => {
    focusRef.current = true;
    handleChangeTypeSearch(e);
    handleClickOutside();
  };

  const handleChangeTyping = (e) => {
    focusRef.current = true;
    handleSearchbox(e);
  };

  useEffect(() => {
    if (!focusRef.current) {
      handleBlur();
    }
  }, [focusRef.current]);

  const onClickTypeDropdown = () => {
    focusRef.current = true;
    setFocusSelect(true);
    setShow(false);
  };

  const handleEnterKeyDown = (e) => {
    handleKeyDown(e);
    if (e.keyCode === 13) setShow(false);
  };
  const handleClickFocus = () => {
    handleFocus();
    setIsFocus(true);
    if (!isFocus) {
      gtag.showHashtagTopSearch(hashtagTopSearch[currentIndex]);
    }
  };

  const handleClickOutside = () => {
    if (!isFocus) return;

    document.body.style.overflow = 'auto';
    setIsVisibleSuggest(true);
    focusRef.current = false;
    setShow(false);
    setSearchIcon(false);
  };

  useEffect(() => {
    if (!isVisibleSuggest) {
      handleShowFirstClick();
    }
  }, [isVisibleSuggest]);

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        handleClickOutside();
      }
    };
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [isFocus]);
  useEffect(() => {
    handleClickOutside();
  }, [router.asPath]);

  if (deviceUtils.isTablet()) {
    return (
      <Box ref={containerRef} className={clsx(styles.search_wrap, classCustom, styles.search_ic_tablet)} onBlur={handleOnBlur}>
        <div className={clsx(styles.searchTabletContainer, isFocus && styles.border_search_tablet)}>
          {!scrollSticky && (
            <>
              {/* Search input */}
              <TextField
                className={styles.text_field_tablet}
                onClick={() => {
                  setIsVisibleSuggest(false);
                }}
                inputRef={inputEl}
                value={keyword}
                classes={{
                  root: styles.root_input_tablet,
                }}
                ref={wrapperRef}
                async
                onChange={handleChangeTyping}
                onKeyDown={handleEnterKeyDown}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image src={seacrIcon ? SEARCHV2ACTIVE_ICON : SEARCHV2_ICON} height="20px" width="20px" layout="fixed" />
                    </InputAdornment>
                  ),
                  placeholder: 'Tìm kiếm ...',
                  autoComplete: 'off',
                }}
                onFocus={handleFocus}
              />
              {/* Dropdown menu */}
              <div className={styles.selectSearch}>
                <FormControl className={styles.formControlTablet}>
                  <NativeSelect
                    className={clsx(focusSelect ? styles.selectInputFocus : styles.selectInput, styles.border_none)}
                    inputProps={{
                      name: 'search',
                      id: 'search-type',
                      placeholder: 'Thuốc',
                    }}
                    IconComponent={ExpandMoreIcon}
                    onChange={handleChangeSelect}
                    onClick={onClickTypeDropdown}
                    onBlur={() => setFocusSelect(false)}
                    value={SEARCH_TYPE_LIST.find((item) => item?.value === Number(searchType)).value || SEARCH_TYPE_LIST[0]?.value}
                    onKeyDown={handleKeyDown}
                  >
                    {SEARCH_TYPE_LIST.map((item) => (
                      <option key={item.value} value={item.value} style={{ cursor: 'pointer', color: '#000000' }}>
                        {item.label}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>
            </>
          )}
        </div>
        {show && <SearchDropdown handleClickOutside={handleClickOutside} keyword={keyword} data={getDataSearch()} type={Number(searchType)} />}
      </Box>
    );
  }

  return (
    <Box ref={containerRef} className={clsx(styles.search_wrap, classCustom)} onBlur={handleOnBlur}>
      <div className={clsx(styles.searchContainer, keyword?.length === 250 && styles.err_border)} data-test="search-container">
        {/* Search input */}
        <TextField
          className={styles.root_input_scale}
          onClick={() => {
            setIsVisibleSuggest(false);
          }}
          inputRef={inputEl}
          value={keyword}
          {...restProps}
          classes={{
            root: clsx(styles.root_input, checkStickyInput()),
          }}
          ref={wrapperRef}
          async
          onChange={handleChangeTyping}
          onKeyDown={handleEnterKeyDown}
          inputProps={{
            maxLength: 250,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ImageFallbackStatic src={seacrIcon ? SEARCHV2ACTIVE_ICON : SEARCHV2_ICON} height="20px" width="20px" />
              </InputAdornment>
            ),
            placeholder: hashtagTopSearch?.length > 0 && !show ? '' : hashtagTopSearch[currentIndex]?.hashtag || 'Bạn tìm gì hôm nay?',
            autoComplete: 'off',
            type: 'search',
          }}
          onFocus={handleClickFocus}
          data-test="search-input"
        />

        {isVisibleSuggest && hashtagTopSearch?.length > 0 && keyword?.trim()?.length === 0 && (
          <div ref={suggestKeywordsRef} className={styles.placeholder_wrapper} onClick={() => setIsVisibleSuggest(false)} role="button">
            {hashtagTopSearch.map((suggestKeyword) => (
              <div key={suggestKeyword.code} className={clsx(styles.placeholder, 'suggest_keyword')}>
                <span>{suggestKeyword.hashtag}</span>
              </div>
            ))}
          </div>
        )}

        {/* Dropdown menu */}
        <div className={clsx(styles.selectSearch, checkStickySelect())}>
          <FormControl className={styles.formControl}>
            <span className={styles?.customHr} />
            <NativeSelect
              className={styles.selectInput}
              inputProps={{
                name: 'search',
                id: 'search-type',
              }}
              IconComponent={(props) => <ExpandIcon {...props} />}
              onChange={handleChangeSelect}
              onClick={onClickTypeDropdown}
              onBlur={() => setFocusSelect(false)}
              value={SEARCH_TYPE_LIST.find((item) => item?.value === Number(searchType))?.value || SEARCH_TYPE_LIST[0]?.value}
              data-test="search-select-option"
            >
              {SEARCH_TYPE_LIST.map((item) => (
                <option key={item.value} value={item.value} style={{ cursor: 'pointer', color: '#000000' }}>
                  {item.label}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
      </div>
      {show && <SearchDropdown handleClickOutside={handleClickOutside} keyword={keyword} data={getDataSearch()} type={Number(searchType)} />}{' '}
      <OverlayV2 isOpen={show} level={2} />
    </Box>
  );
});

export default SearchInput;
